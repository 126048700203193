
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Timer from "./Timer.vue";
import Question from "@/models/question";

@Component({
  components: { Timer }
})
export default class HeaderExam extends Vue {
  private shikenId: number = 0
  created() {
    const datas:Question[] = this.$store.state.data
    if (datas.length == 0) return
    this.shikenId = datas[0].shikenId
  }
  mounted() {

  }

  tapLogo() {
    new BasicUtil().reload(this.shikenId);
  }

  

  private endForce() {
    alert("制限時間が終了しました")
    this.$router.push(`/end`);
  }
}
