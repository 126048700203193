
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Question from "../../models/question";
import StorageService from "../../services/storage";
import question from "@/services/question";
@Component({
  components: {}
})
export default class Choice extends Vue {
  @Prop()
  private question!: Question;
  @Prop()
  private index!: number;
  @Prop()
  private indexs!: number[];
  @Prop()
  public questions!: Question[]

  private isLock: boolean = false;
  mounted() {}


  @Emit("answerEmit")
  answerEmit () {
    return
  }

  public async answer(i: number) {
    this.questions[this.index].result = this.question.answer == i;
    this.questions[this.index].currentChoice = i;
    this.$store.commit("setQuestions", this.questions);
    this.answerEmit();
  }
}
