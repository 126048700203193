
import { Component, Vue, Emit } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import BasicUtil from "../services/basicUtil";
@Component({
  components: { Header }
})
export default class AuthView extends Vue {
  private qas: string[] = [];
  private answer: string = "";
  private answer2: string = "";
  private answer3: string = "";
  private grade: string = this.$route.name == "auth" ? "3" : "33";
  private gradeStr: string = "";
  private alreadyAuth1: boolean = false;
  private isCheck: boolean = false;
  private isError: boolean = false;

  created() {
    this.grade = this.$route.name == "auth" ? "3" : "33"
    document.getElementById("body")!.classList.add("auth-body");
    this.alreadyAuth1 = BasicUtil.isAuth(this.grade);
    if (this.alreadyAuth1) {
      
      if (this.grade == "3") {
        this.$router.push("/")
      } else {
        this.$router.push("/home2")
      }
      return
    }
      this.gradeStr = "3級";
    this.$nextTick(function() {
      this.qas = BasicUtil.getQa3();
    });
  }
  private answerQa() {
    this.isError = false
    if (this.grade == "3") {
    if (BasicUtil.zentoHanNumber(this.answer) != this.qas[0]) {
      this.isError = true
      return;
    }
  } else {
    if (BasicUtil.zentoHanNumber(this.answer2) != "536" && BasicUtil.zentoHanNumber(this.answer3) != "60") {
      this.isError = true
      return;
    }
  }
    BasicUtil.setGradePass(this.grade);
    this.alreadyAuth1 = true;
    if (this.grade == "3") {
    location.href = "/"

    } else {
      location.href = "/home2"
    }
  }
}
