import { render, staticRenderFns } from "./HeaderExam.vue?vue&type=template&id=e1b4ebca&scoped=true&"
import script from "./HeaderExam.vue?vue&type=script&lang=ts&"
export * from "./HeaderExam.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderExam.vue?vue&type=style&index=0&id=e1b4ebca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1b4ebca",
  null
  
)

export default component.exports