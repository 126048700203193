
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";


@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();

  private grade: string = this.$route.name == "home" ? "3" : "33";
  private shikenId: number = this.$route.name == "home" ? 1 : 2;
  created() {
    const isAuth = BasicUtil.isAuth(this.grade);
    if (this.grade == "3") {
      if (!isAuth) this.$router.push("/auth");
    } else {
      if (!isAuth) this.$router.push("/auth2");
    }
  }
  mounted() {
    new BasicUtil().removeBasicSetting(this.shikenId);
    new StorageService().removeCurrentData();
  }

  private exec(shikenId: number) {
    const datas = new HttpClient().getQuestionsFromAnswerType(shikenId, 1)
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    this.$store.commit("setIsRe", false)
    this.$store.commit("setGoal", new Date().getTime() + 120 * 60 * 1000);
    new BasicUtil().setBasicSetting(shikenId);
    this.$router.push(`/exam/${shikenId}/1`);
  }

}
