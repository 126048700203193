
export default [
    {
        "id": 1,
        "shikenId": 1,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "公的介護保険の第２号被保険者は、市町村または特別区の区域内に住所を有する65歳以上の者である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 2,
        "shikenId": 1,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "全国健康保険協会管掌健康保険の被保険者である会社員が、退職後に任意継続被保険者となるためには、資格喪失日から14日以内に任意継続被保険者となるための申出をしなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 3,
        "shikenId": 1,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "老齢厚生年金の繰下げ支給の申出は、老齢基礎年金の繰下げ支給の申出と同時に行う必要はない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 4,
        "shikenId": 1,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "国民年金の第３号被保険者は、確定拠出年金の個人型年金の加入者となることはできない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 5,
        "shikenId": 1,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "日本政策金融公庫の教育一般貸付（国の教育ローン）は、日本学生支援機構の奨学金制度と重複して利用することができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 6,
        "shikenId": 1,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "延長保険とは、一般に、現在加入している生命保険の保険料の払込みを中止し、その時点での解約返戻金を基に、元契約の保険金額を変えずに一時払いの定期保険に変更する制度である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 7,
        "shikenId": 1,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "一時払終身保険は、早期に解約した場合であっても、解約返戻金額が一時払保険料相当額を下回ることはない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 8,
        "shikenId": 1,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "自動車損害賠償責任保険（自賠責保険）では、被保険者自身が単独事故でケガをした場合、その損害は補償の対象とならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 9,
        "shikenId": 1,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "家族傷害保険の被保険者の範囲には、被保険者本人と生計を共にする別居の未婚の子も含まれる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 10,
        "shikenId": 1,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "所得税において、個人が支払う地震保険の保険料は、５万円を限度として年間支払保険料の２分の１相当額が地震保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 11,
        "shikenId": 1,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "米国の市場金利が上昇し、日本と米国の金利差が拡大することは、一般に、米ドルと円の為替相場において米ドル安、円高の要因となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 12,
        "shikenId": 1,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "インデックス型投資信託は、日経平均株価や東証株価指数（TOPIX）などの特定の指標に連動するよう運用される投資信託である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 13,
        "shikenId": 1,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "残存期間や表面利率（クーポンレート）等の他の条件が同一であれば、一般に、格付の高い債券ほど安全性が高いため、利回りが高くなる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 14,
        "shikenId": 1,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "配当性向とは、当期純利益に占める配当金総額の割合を示す指標である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 15,
        "shikenId": 1,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "特定口座を開設している金融機関に、NISA口座（少額投資非課税制度における非課税口座）を開設した場合、特定口座内の株式投資信託をNISA口座に移管することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 16,
        "shikenId": 1,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "所得税において、医療保険の被保険者が病気で入院したことにより受け取った入院給付金は、非課税である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 17,
        "shikenId": 1,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付による所得は、事業所得となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 18,
        "shikenId": 1,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "所得税において、個人が確定拠出年金の個人型年金に加入し、拠出した掛金は、社会保険料控除の対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 19,
        "shikenId": 1,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "住宅ローンを利用して住宅を新築した個人が、所得税の住宅借入金等特別控除の適用を受けるためには、当該住宅を新築した日から１カ月以内に自己の居住の用に供さなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 20,
        "shikenId": 1,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "給与所得者のうち、その年中に支払を受けるべき給与の収入金額が1,000万円を超える者は、所得税の確定申告をしなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 21,
        "shikenId": 1,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "不動産の権利関係を確認するために、当該不動産の所有者以外の者であっても、登記事項証明書の交付を請求することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 22,
        "shikenId": 1,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "借地借家法において、事業用定期借地権等の設定を目的とする契約は、公正証書によってしなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 23,
        "shikenId": 1,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "都市計画法において、市街化調整区域とは、おおむね10年以内に計画的に市街化を図るべき区域である。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 24,
        "shikenId": 1,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "建築基準法の規定によれば、建築物の敷地が２つの異なる用途地域にまたがる場合、その全部について、建築物の用途制限がより厳しい地域における建築物の用途に関する規定が適用される。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 25,
        "shikenId": 1,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "「居住用財産を譲渡した場合の3,000万円の特別控除」は、自己が居住していた家屋を配偶者や子に譲渡した場合には、適用を受けることができない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 26,
        "shikenId": 1,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "個人間において著しく低い価額で財産の譲渡が行われた場合、原則として、その譲渡があった時の譲渡財産の時価と支払った対価との差額に相当する金額について、贈与税の課税対象となる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 27,
        "shikenId": 1,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "子が父親からの贈与により取得した財産について相続時精算課税の適用を受けた場合、その適用を受けた年以後、子は父親からの贈与により取得した財産について暦年課税を選択することはできない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 28,
        "shikenId": 1,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "協議分割は、共同相続人全員の協議により遺産を分割する方法であり、その分割割合については、必ずしも法定相続分に従う必要はない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 29,
        "shikenId": 1,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "相続人が負担した被相続人の葬式の際の香典返戻費用は、相続税の課税価格の計算上、葬式費用として控除することができる。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 30,
        "shikenId": 1,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕の番号を選択してください。",
        "question2": "相続税額の計算において、「配偶者に対する相続税額の軽減」の適用を受けるためには、その適用を受けることにより納付すべき相続税額が算出されない場合であっても、相続税の申告書を提出しなければならない。",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 31,
        "shikenId": 1,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "900万円を準備するために、15年間、毎年均等に積み立て、利率（年率）１％で複利運用する場合、必要となる毎年の積立金額は、下記の＜資料＞の係数を使用して算出すると（　　）である。<br><br>＜資料＞利率（年率）１％・期間15年の各種係数<br><table><tr><td>現価係数</td><td>資本回収係数</td><td>減債基金係数</td></tr><tr><td>0.8613</td><td>0.0721</td><td>0.0621</td></tr></table>",
        "choice1": "516,780円",
        "choice2": "558,900円",
        "choice3": "600,000円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 32,
        "shikenId": 1,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前（①）に被保険者期間が通算して（②）以上あることなどの要件を満たす必要がある。",
        "choice1": "①　１年間　　②　６カ月",
        "choice2": "①　２年間　　②　６カ月",
        "choice3": "①　２年間　　②　12カ月",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 33,
        "shikenId": 1,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "国民年金の被保険者が学生納付特例制度の適用を受けた期間は、その期間に係る保険料を追納しない場合、老齢基礎年金の受給資格期間（①）、老齢基礎年金の年金額（②）。",
        "choice1": "①に算入され　　②にも反映される",
        "choice2": "①に算入されず　　②にも反映されない",
        "choice3": "①には算入されるが　　②には反映されない",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 34,
        "shikenId": 1,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "遺族厚生年金の額（中高齢寡婦加算額および経過的寡婦加算額を除く）は、原則として、死亡した者の厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の（　　）に相当する額である。",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 35,
        "shikenId": 1,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "住宅ローンの返済方法のうち、元利均等返済は、毎月の返済額が一定で、返済期間の経過とともに毎月の元金部分の返済額が（①）返済方法であり、総返済金額は、他の条件が同一である場合、通常、元金均等返済よりも（②）。",
        "choice1": "①　減少する　　②　多い",
        "choice2": "①　増加する　　②　多い",
        "choice3": "①　増加する　　②　少ない",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 36,
        "shikenId": 1,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "少額短期保険業者による取扱商品は「少額・短期・掛捨て」に限定され、１人の被保険者から引き受ける保険金額の総額は、原則として（　　）が上限となっている。",
        "choice1": "1,000万円",
        "choice2": "1,200万円",
        "choice3": "1,500万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 37,
        "shikenId": 1,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "団体を契約者（＝保険料負担者）とし、その所属員を被保険者とする１年更新の定期保険であり、福利厚生規程等による保障の支払財源の確保を目的とした保険は、（　　）である。",
        "choice1": "総合福祉団体定期保険",
        "choice2": "団体定期保険（Ｂグループ保険）",
        "choice3": "団体信用生命保険",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 38,
        "shikenId": 1,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "地震保険の保険金額は、火災保険の保険金額の30％から50％の範囲内で設定されるが、居住用建物については（①）、生活用動産（家財）については（②）が上限となる。",
        "choice1": "①　1,500万円　　②300万円",
        "choice2": "①　3,000万円　　②500万円",
        "choice3": "①　5,000万円　　②1,000万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 39,
        "shikenId": 1,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "歩行中に交通事故でケガをし、加害車両の運転者が加入していた自動車保険の対人賠償保険から受け取った保険金は、所得税において、（　　）とされる。",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "非課税所得",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 40,
        "shikenId": 1,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "リビング・ニーズ特約は、（①）、被保険者の余命が（②）以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。",
        "choice1": "①　病気やケガの種類にかかわらず　　②　６カ月",
        "choice2": "①　病気やケガの種類にかかわらず　　②　１年",
        "choice3": "①　特定疾病に罹患したことが原因で　　②　１年",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 41,
        "shikenId": 1,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "景気動向指数において、（　　）は、一致系列に採用されている。",
        "choice1": "完全失業率",
        "choice2": "新規求人数（除学卒）",
        "choice3": "有効求人倍率（除学卒）",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 42,
        "shikenId": 1,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "表面利率（クーポンレート）１％、残存期間２年の固定利付債券を額面100円当たり99円で購入した場合の最終利回り（年率・単利）は、（　　）である。なお、税金等は考慮しないものとし、計算結果は表示単位の小数点以下第３位を四捨五入している。",
        "choice1": "1.50％",
        "choice2": "1.52％",
        "choice3": "2.02％",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 43,
        "shikenId": 1,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "追加型株式投資信託を基準価額１万4,000円で１万口購入した後、最初の決算時に１万口当たり300円の収益分配金が支払われ、分配落ち後の基準価額が１万3,800円となった場合、その収益分配金のうち、普通分配金は（①）であり、元本払戻金（特別分配金）は（②）である。",
        "choice1": "①　０円　　②　300円",
        "choice2": "①　100円　　②　200円",
        "choice3": "①　200円　　②　100円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 44,
        "shikenId": 1,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "個人向け国債は、金利の下限が年（①）とされ、購入単価は最低（②）から（②）単位である。",
        "choice1": "①　0.03％　　②　10万円",
        "choice2": "①　0.05％　　②　１万円",
        "choice3": "①　0.05％　　②　10万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 45,
        "shikenId": 1,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "相関係数が（　　）である２資産に投資するポートフォリオにおいては、両資産が同一の値動きをするため、分散投資によるリスク低減効果は得られない。",
        "choice1": "－１",
        "choice2": "０",
        "choice3": "＋１",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 46,
        "shikenId": 1,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "給与所得者が25年間勤務した会社を定年退職し、退職金2,000万円の支給を受けた場合、所得税における退職所得の金額の計算上、退職所得控除額は、（　　）となる。",
        "choice1": "{800万円＋70万円×（25年－20年）}×１/２＝575万円",
        "choice2": "800万円＋40万円×（25年－20年）＝1,000万円",
        "choice3": "800万円＋70万円×（25年－20年）＝1,150万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 47,
        "shikenId": 1,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "国内において支払を受ける預貯金の利子は、原則として、所得税および復興特別所得税と住民税の合計で（①）の税率による（②）分離課税の対象となる。",
        "choice1": "①　10.21％　　②　申告",
        "choice2": "①　20.315％　　②　申告",
        "choice3": "①　20.315％　　②　源泉",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 48,
        "shikenId": 1,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "Aさんの2023年分の各種所得の金額が下記の＜資料＞のとおりであった場合、損益通算後の総所得金額は（　　）となる。なお、各種所得の金額に付されている「▲」は、その所得に損失が生じていることを表すものとする。<br><br>＜資料＞Aさんの各種所得の金額<br><table><tr><td>不動産所得の金額</td><td>750万円</td></tr><tr><td>雑所得の金額</td><td>▲50万円</td></tr><tr><td>事業所得の金額（株式等に係るものを除く）</td><td>▲150万円</td></tr></table>",
        "choice1": "550万円",
        "choice2": "600万円",
        "choice3": "700万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 49,
        "shikenId": 1,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "所得税において、控除対象扶養親族のうち、その年の12月31日時点の年齢が19歳以上23歳未満である特定扶養親族に係る扶養控除の額は、１人につき（　　）である。",
        "choice1": "38万円",
        "choice2": "48万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 50,
        "shikenId": 1,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "年末調整の対象となる給与所得者は、年末調整により、（　　）の適用を受けることができる。",
        "choice1": "生命保険料控除",
        "choice2": "雑損控除",
        "choice3": "医療費控除",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 51,
        "shikenId": 1,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "相続税路線価は、相続税や（①）を算定する際の土地等の評価額の基準となる価格であり、地価公示法による公示価格の（②）を価格水準の目安として設定される。",
        "choice1": "①　不動産取得税　　②　70％",
        "choice2": "①　贈与税　　②　70％",
        "choice3": "①　贈与税　　②　80％",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 52,
        "shikenId": 1,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "不動産の売買契約において、買主が売主に解約手付を交付した場合、売主は、（①）が契約の履行に着手するまでは、受領した手付（②）を買主に提供することで、契約の解除をすることができる。",
        "choice1": "①　買主　　②　と同額",
        "choice2": "①　買主　　②　の倍額",
        "choice3": "①　売主　　②　と同額",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 53,
        "shikenId": 1,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "建物の区分所有等に関する法律（区分所有法）上、集会においては、区分所有者および議決権の各（　　）以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議（建替え決議）をすることができる。",
        "choice1": "３分の２",
        "choice2": "４分の３",
        "choice3": "５分の４",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 54,
        "shikenId": 1,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "個人が土地・建物を譲渡したことによる譲渡所得の金額の計算において、譲渡した土地・建物の取得費が不明である場合、譲渡収入金額の（　　）相当額を取得費とすることができる。",
        "choice1": "３％",
        "choice2": "５％",
        "choice3": "10％",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 55,
        "shikenId": 1,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "土地の有効活用方式のうち、一般に、土地所有者が土地の全部または一部を拠出し、デベロッパーが建設費等を拠出して、それぞれの出資比率に応じて土地・建物に係る権利を取得する方式を、（　　）という。",
        "choice1": "等価交換方式",
        "choice2": "事業受託方式",
        "choice3": "建設協力金方式",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 56,
        "shikenId": 1,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "贈与税の申告書は、原則として、贈与を受けた年の翌年の（①）から３月15日までの間に、（②）の住所地を所轄する税務署長に提出しなければならない。",
        "choice1": "①　２月１日　　②　受贈者",
        "choice2": "①　２月16日　　②　贈与者",
        "choice3": "①　２月16日　　②　受贈者",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 57,
        "shikenId": 1,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "2023年中に開始した相続において、相続人が被相続人の配偶者と子３人の計４人である場合、相続税額の計算における遺産に係る基礎控除額は、（　　）となる。",
        "choice1": "5,000万円",
        "choice2": "5,400万円",
        "choice3": "5,800万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 58,
        "shikenId": 1,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "公正証書遺言は、証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がそれを筆記して作成される遺言であり、相続開始後に（①）における検認手続が（②）である。",
        "choice1": "①家庭裁判所　　②不要",
        "choice2": "①家庭裁判所　　②必要",
        "choice3": "①公証役場　　②必要",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 59,
        "shikenId": 1,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "下記の＜親族関係図＞において、Aさんの相続における妻Bさんの法定相続分は、（　　）である。<br><br>＜親族関係図＞<br><img src=\"/img/59.png\"/>",
        "choice1": "２分の１",
        "choice2": "３分の２",
        "choice3": "４分の３",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 60,
        "shikenId": 1,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選びなさい。",
        "question2": "自用地としての価額が5,000万円、借地権割合が70％、借家権割合が30％、賃貸割合が100％の貸家建付地の相続税評価額は、（　　）である。",
        "choice1": "1,500万円",
        "choice2": "3,500万円",
        "choice3": "3,950万円",
        "choice4": "",
        "choice5": ""
    },
    {
        "id": 61,
        "shikenId": 2,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "弁護士の資格を有しないファイナンシャル・プランナーが、顧客に対して、法定後見制度と任意後見制度の違いについて一般的な説明を行う行為は、弁護士法に抵触する。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 62,
        "shikenId": 2,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "全国健康保険協会管掌健康保険の被保険者が、業務外の事由による負傷または疾病の療養のため、労務に服することができずに休業し、報酬を受けられなかった場合は、その労務に服することができなくなった日から傷病手当金が支給される。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 63,
        "shikenId": 2,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時、その者によって生計を維持され、かつ、所定の要件を満たす「子のある配偶者」または「子」である。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 64,
        "shikenId": 2,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "確定拠出年金の個人型年金の加入者が国民年金の第1号被保険者である場合、原則として、掛金の拠出限度額は年額816,000円である。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 65,
        "shikenId": 2,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "日本政策金融公庫の教育一般貸付（国の教育ローン）は、日本学生支援機構の奨学金制度と重複して利用することができない。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 66,
        "shikenId": 2,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "保険業法上の保険募集において、保険募集人が保険契約の締結の媒介を行う場合、保険募集人が保険契約の申込みに対して承諾した時点で当該保険契約は有効に成立する。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 67,
        "shikenId": 2,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "延長保険とは、一般に、保険料の払込みを中止して、その時点での解約返戻金を基に、元契約よりも長い保険期間の定期保険に変更する制度である。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 68,
        "shikenId": 2,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "変額個人年金保険は、特別勘定による運用実績によって、将来受け取る年金額や解約返戻金額が変動する。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 69,
        "shikenId": 2,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "こども保険（学資保険）において、保険期間中に契約者（＝保険料負担者）である親が死亡した場合、一般に、既払込保険料相当額の死亡保険金が支払われて契約は消滅する。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 70,
        "shikenId": 2,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "自動車保険の人身傷害補償保険では、被保険者が被保険自動車を運転中、自動車事故により負傷した場合、自己の過失割合にかかわらず、保険金額を限度に損害額が補償される。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 71,
        "shikenId": 2,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "米国の市場金利が上昇し、日本と米国の金利差が拡大することは、一般に、米ドルと円の為替相場において米ドル安、円高の要因となる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 72,
        "shikenId": 2,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "インデックスファンドは、日経平均株価や東証株価指数（TOPIX）などの特定の指標に連動することを目指して運用されるファンドである。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 73,
        "shikenId": 2,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "一般に、残存期間や表面利率（クーポンレート）が同一であれば、格付の高い債券ほど利回りが低く、格付の低い債券ほど利回りが高くなる。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 74,
        "shikenId": 2,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "上場株式の売買において、普通取引は約定日の翌営業日に決済が行われる。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 75,
        "shikenId": 2,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "オプション取引において、特定の商品を将来の一定期日に、あらかじめ決められた価格（権利行使価格）で買う権利のことをプット・オプションという。(2020.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 76,
        "shikenId": 2,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "電車・バス等の交通機関を利用して通勤している給与所得者が、勤務先から受ける通勤手当は、所得税法上、月額10万円を限度に非課税とされる。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 77,
        "shikenId": 2,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "個人が国内において支払を受ける預貯金の利子は、原則として、20.315％の税率により所得税および復興特別所得税と住民税が源泉徴収等され、課税関係が終了する。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 78,
        "shikenId": 2,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "夫が生計を一にする妻の負担すべき国民年金の保険料を支払った場合、その支払った金額は、夫に係る所得税の社会保険料控除の対象となる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 79,
        "shikenId": 2,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "給与所得者が所得税の住宅借入金等特別控除の適用を受ける場合、その適用を受ける最初の年分については、年末調整の対象者であっても、確定申告をしなければならない。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 80,
        "shikenId": 2,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "所得税において、青色申告者に損益通算してもなお控除しきれない損失の金額（純損失の金額）が生じた場合、その損失の金額を翌年以後最長で5年間繰り越して、翌年以後の所得金額から控除することができる。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 81,
        "shikenId": 2,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産登記には公信力が認められていないため、登記記録上の権利者が真実の権利者と異なっている場合に、登記記録を信じて不動産を購入した者は、原則として、その不動産に対する権利の取得について法的に保護されない。(2023.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 82,
        "shikenId": 2,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "借地借家法において、事業用定期借地権等は、専ら事業の用に供する建物の所有を目的とするものであり、居住の用に供する建物の所有を目的として設定することはできない。(2021.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 83,
        "shikenId": 2,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "建築基準法上、容積率とは、建築物の建築面積の敷地面積に対する割合をいう。(2021.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 84,
        "shikenId": 2,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "不動産取得税は、相続人が不動産を相続により取得した場合には課されない。(2022.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 85,
        "shikenId": 2,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "個人が土地を譲渡したことによる譲渡所得の金額の計算において、譲渡した土地の取得費が不明である場合、当該収入金額の10％相当額を取得費とすることができる。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 86,
        "shikenId": 2,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "定期贈与とは、贈与者が受贈者に対して定期的に財産を給付することを目的とする贈与をいい、贈与者または受贈者の死亡によって、その効力を失う。(2023.01)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 87,
        "shikenId": 2,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "養子には、普通養子と特別養子があり、普通養子は養子縁組により実方の父母との親族関係が終了しない。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 88,
        "shikenId": 2,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "個人が、自己が所有する土地に賃貸マンションを建築して賃貸の用に供した場合、相続税額の計算上、当該敷地は貸宅地として評価される。(2022.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 89,
        "shikenId": 2,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "被相続人の配偶者が、被相続人の居住の用に供されていた宅地を相続により取得した後、当該宅地を相続税の申告期限までに売却した場合、当該宅地は、相続税の課税価格の計算上、特定居住用宅地等として「小規模宅地等についての相続税の課税価格の計算の特例」の適用を受けることができない。(2021.05)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 90,
        "shikenId": 2,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。",
        "question2": "相続税額の計算において、「配偶者に対する相続税額の軽減」の適用を受けるためには、その適用を受けることにより納付すべき相続税額が算出されない場合であっても、相続税の申告書を提出しなければならない。(2022.09)",
        "choice1": "〇",
        "choice2": "×",
        "choice3": "",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 91,
        "shikenId": 2,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "一定の利率で複利運用しながら一定期間経過後に目標とする額を得るために必要な毎年の積立額を試算する際、目標とする額に乗じる係数は、（   ）である。(2022.09)",
        "choice1": "減債基金係数",
        "choice2": "年金現価係数",
        "choice3": "資本回収係数",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 92,
        "shikenId": 2,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "雇用保険の基本手当を受給するためには、倒産、解雇、雇止めなどの場合を除き、原則として、離職の日以前（①）に被保険者期間が通算して（②）以上あることなどの要件を満たす必要がある。(2023.09)",
        "choice1": "① 1年間　　② 6カ月",
        "choice2": "① 2年間　　② 6カ月",
        "choice3": "① 2年間　　② 12カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 93,
        "shikenId": 2,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "65歳到達時に老齢基礎年金の受給資格期間を満たしている者が、67歳0カ月で老齢基礎年金の繰下げ支給の申出をした場合、老齢基礎年金の増額率は、（  ）となる。(2021.05)",
        "choice1": "12.0％",
        "choice2": "16.8％",
        "choice3": "25.2％",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 94,
        "shikenId": 2,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "子のいない障害等級1級に該当する者に支給される障害基礎年金の額は、子のいない障害等級2級に該当する者に支給される障害基礎年金の額の（  ）に相当する額である。(2022.05)",
        "choice1": "0.75倍",
        "choice2": "1.25倍",
        "choice3": "1.75倍",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 95,
        "shikenId": 2,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "住宅ローンの返済方法のうち、元利均等返済は、毎月の返済額が（①）、返済期間の経過とともに毎月の元金の返済額が（②）返済方法である。(2022.01)",
        "choice1": "① 減少し　　② 増加する",
        "choice2": "① 一定で　　② 減少する",
        "choice3": "① 一定で　　② 増加する",
        "choice4": "",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 96,
        "shikenId": 2,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約については、高予定利率契約を除き、（①）の（②）まで補償される。(2022.09)",
        "choice1": "① 既払込保険料相当額　　② 70％",
        "choice2": "① 死亡保険金額　　② 80％",
        "choice3": "① 責任準備金等　　② 90％",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 97,
        "shikenId": 2,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "地震保険の保険金額は、火災保険の保険金額の（①）の範囲内で設定することになるが、居住用建物については（②）、生活用動産（家財）については1,000万円が上限となる。(2022.09)",
        "choice1": "① 30％から50％まで　　② 3,000万円",
        "choice2": "① 30％から50％まで　　② 5,000万円",
        "choice3": "① 50％から80％まで　　② 5,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 98,
        "shikenId": 2,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "スーパーマーケットを経営する企業が、店舗内で調理・販売した食品が原因で食中毒を発生させ、顧客に対して法律上の損害賠償責任を負うことによって被る損害を補償する保険として、（  ）がある。(2022.05)",
        "choice1": "生産物賠償責任保険（PL保険）",
        "choice2": "請負業者賠償責任保険",
        "choice3": "施設所有（管理）者賠償責任保険",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 99,
        "shikenId": 2,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "民法および失火の責任に関する法律（失火責任法）において、借家人が軽過失によって火事を起こし、借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を（①）。また、隣家の所有者に対して損害賠償責任を（②）。(2022.01)",
        "choice1": "① 負う　　② 負わない",
        "choice2": "① 負う　　② 負う",
        "choice3": "① 負わない　　② 負う",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 100,
        "shikenId": 2,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "医療保険等に付加される先進医療特約では、（  ）時点において厚生労働大臣により定められている先進医療が給付の対象となる。(2021.09)",
        "choice1": "申込日",
        "choice2": "責任開始日",
        "choice3": "療養を受けた日",
        "choice4": "",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 101,
        "shikenId": 2,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "景気動向指数において、有効求人倍率（除学卒）は、（  ）に採用されている。(2022.09)",
        "choice1": "先行系列",
        "choice2": "一致系列",
        "choice3": "遅行系列",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 102,
        "shikenId": 2,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "投資信託の運用において、企業の成長性が市場平均よりも高いと見込まれる銘柄に投資する手法を、（  ）という。(2023.01)",
        "choice1": "パッシブ運用",
        "choice2": "バリュー運用",
        "choice3": "グロース運用",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 103,
        "shikenId": 2,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "株式の投資指標である（  ）は、株価を1株当たり当期純利益で除して算出される。(2022.01)",
        "choice1": "PBR",
        "choice2": "PER",
        "choice3": "ROE",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 104,
        "shikenId": 2,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "A資産の期待収益率が3.0％、B資産の期待収益率が2.0％の場合に、A資産を80％、B資産を20％の割合で組み入れたポートフォリオの期待収益率は、（  ）となる。(2021.05)",
        "choice1": "2.4％",
        "choice2": "2.8％",
        "choice3": "6.0％",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 105,
        "shikenId": 2,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "預金保険制度の対象金融機関に預け入れた（  ）は、預入金額の多寡にかかわらず、その全額が預金保険制度による保護の対象となる。(2022.09)",
        "choice1": "決済用預金",
        "choice2": "譲渡性預金",
        "choice3": "定期預金",
        "choice4": "",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 106,
        "shikenId": 2,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、事業的規模で行われている賃貸マンションの貸付による所得は、（  ）となる。(2023.01)",
        "choice1": "不動産所得",
        "choice2": "事業所得",
        "choice3": "雑所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 107,
        "shikenId": 2,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "給与所得者が30年間勤務した会社を定年退職し、退職金2,500万円の支給を受けた場合、退職所得の金額の計算上、退職所得控除額は（  ）となる。(2022.01)",
        "choice1": "800万円＋40万円×(30年－20年)＝1,200万円",
        "choice2": "800万円＋70万円×(30年－20年)＝1,500万円",
        "choice3": "70万円×30年＝2,100万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 108,
        "shikenId": 2,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、控除対象扶養親族のうち、その年の12月31日時点の年齢が19歳以上23歳未満である特定扶養親族に係る扶養控除の額は、1人につき（  ）である。(2022.05)",
        "choice1": "38万円",
        "choice2": "48万円",
        "choice3": "63万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 109,
        "shikenId": 2,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税において、不動産所得、（  ）、山林所得、譲渡所得の金額の計算上生じた損失の金額は、一定の場合を除き、他の所得の金額と損益通算することができる。(2022.01)",
        "choice1": "一時所得",
        "choice2": "雑所得",
        "choice3": "事業所得",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 110,
        "shikenId": 2,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "所得税の確定申告をしなければならない者は、原則として、所得が生じた年の翌年の（①）から（②）までの間に、納税地の所轄税務署長に対して確定申告書を提出しなければならない。(2021.09)",
        "choice1": "① 2月1日　　② 3月15日",
        "choice2": "① 2月16日　　② 3月15日",
        "choice3": "① 2月16日　　② 3月31日",
        "choice4": "",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 111,
        "shikenId": 2,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "投資総額1億2,000万円で購入した賃貸用不動産の年間収入の合計額が1,050万円、年間費用の合計額が300万円である場合、この投資の純利回り（NOI利回り）は、（  ）である。(2023.01)",
        "choice1": "2.50％",
        "choice2": "6.25％",
        "choice3": "8.75％",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 112,
        "shikenId": 2,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "土地の登記記録において、（①）に関する事項は権利部（甲区）に記録され、（②）に関する事項は権利部（乙区）に記録される。(2022.09)",
        "choice1": "① 所有権　　② 抵当権",
        "choice2": "① 賃借権　　② 抵当権",
        "choice3": "① 賃借権　　② 所有権",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 113,
        "shikenId": 2,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "宅地建物取引業法において、宅地建物取引業者が依頼者と締結する宅地または建物の売買の媒介契約のうち、専任媒介契約の有効期間は、最長（  ）である。(2023.01)",
        "choice1": "1カ月",
        "choice2": "3カ月",
        "choice3": "6カ月",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 114,
        "shikenId": 2,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "建物の区分所有等に関する法律（区分所有法）において、規約の変更は、区分所有者および議決権の各（  ）以上の多数による集会の決議によらなければならない。(2022.05)",
        "choice1": "2分の1",
        "choice2": "3分の2",
        "choice3": "4分の3",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 115,
        "shikenId": 2,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "被相続人の居住用家屋およびその敷地を相続により取得した被相続人の長男が、当該家屋およびその敷地を譲渡し、「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」の適用を受けた場合、譲渡所得の金額の計算上、最高（  ）を控除することができる。(2021.09)",
        "choice1": "2,000万円",
        "choice2": "3,000万円",
        "choice3": "5,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 116,
        "shikenId": 2,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "個人が死因贈与によって取得した財産は、課税の対象とならない財産を除き、（  ）の課税対象となる。(2022.05)",
        "choice1": "贈与税",
        "choice2": "相続税",
        "choice3": "所得税",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 117,
        "shikenId": 2,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "下記の＜親族関係図＞において、Aさんの相続における母Dさんの法定相続分は、（  ）である。(2023.05)<br><img src=\"/img/2_1.png\" style='width: 500px;'/>",
        "choice1": "3分の1",
        "choice2": "4分の1",
        "choice3": "6分の1",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 118,
        "shikenId": 2,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "相続税額の計算上、死亡保険金の非課税金額の規定による非課税限度額は、「（  ）×法定相続人の数」の算式により算出される。(2021.01)",
        "choice1": "500万円",
        "choice2": "600万円",
        "choice3": "1,000万円",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 119,
        "shikenId": 2,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "貸家建付地の相続税評価額は、（  ）の算式により算出される。(2021.09)",
        "choice1": "自用地としての価額×(1－借地権割合×賃貸割合)",
        "choice2": "自用地としての価額×(1－借家権割合×賃貸割合)",
        "choice3": "自用地としての価額×(1－借地権割合×借家権割合×賃貸割合)",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    },
    {
        "id": 120,
        "shikenId": 2,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "次の文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１．〜３．のなかから選びなさい。",
        "question2": "国税庁が公表している路線価図において、路線に「300C」と付されている場合、「C」の記号は、借地権割合が（  ）であることを示している。(2021.05)",
        "choice1": "60％",
        "choice2": "70％",
        "choice3": "80%",
        "choice4": "",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "",
        "important": ""
    }
]