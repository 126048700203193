var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"py-5",staticStyle:{"max-width":"800px","margin":"0 auto"}},[_c('h6',[_vm._v("《注意事項》")]),_c('ol',[_vm._m(0),_vm._m(1),_c('li',[_vm._v("「解く」ボタンをクリックすると試験がスタートします。試験画面には残り時間が表示されます。 ")]),_c('li',[_vm._v("試験問題については、特に指示のない限り2023年４月１日現在施行の法令等に基づいて解答してください。 ")]),_vm._m(2),_vm._m(3),_c('li',[_vm._v("計算が必要な問題は、画面下の「電卓」ボタンをクリックし、ＰＣ上に表示された電卓機能をご利用ください。")]),_vm._m(4),_vm._m(5),(_vm.$route.name == 'home2')?_c('li',[_vm._v("本ＣＢＴ模試は過去問題より出題しています。問題文の最後のカッコ内の数字は、出題年月を表しています。")]):_vm._e()]),_c('div',{staticClass:"mt-5"},[(_vm.$route.name == 'home')?_c('table',{staticClass:"top-list"},[_vm._m(6),_c('tr',[_c('td',[_vm._v("学科試験 チャレンジ１")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary D4ckn6yw",on:{"click":function($event){return _vm.exec(1)}}},[_vm._v("解く")])])])]):_c('table',{staticClass:"top-list"},[_vm._m(7),_c('tr',[_c('td',[_vm._v("学科試験 体験版")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-primary D4ckn6yw",on:{"click":function($event){return _vm.exec(2)}}},[_vm._v("解く")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("問題の出題形式は、"),_c('strong',[_vm._v("正誤式 30問、三答択一式30問")]),_vm._v("です。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("試験時間は、"),_c('strong',[_vm._v("120分")]),_vm._v("です。試験時間が経過すると自動的に終了します。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("試験中は「"),_c('strong',[_vm._v("次へ")]),_vm._v("」「"),_c('strong',[_vm._v("前へ")]),_vm._v("」ボタンで、問題画面を進めてください。"),_c('br'),_vm._v("ブラウザの戻る・進むボタンで画面を移動すると、エラーとなりますのでご注意ください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("画面下の「"),_c('strong',[_vm._v("解答状況")]),_vm._v("」ボタンをクリックすると、未解答となっている問題や「"),_c('strong',[_vm._v("後で見直す")]),_vm._v("」ボタンでチェックした問題が確認できます。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("試験問題は「"),_c('strong',[_vm._v("試験終了")]),_vm._v("」ボタンをクリック、または制限時間になると試験が終了し、試験結果が表示されます。"),_c('br'),_vm._v("※この機能は本試験にはありません。復習にお役立てください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_vm._v("問１～問30は、文章を読んで、正しいものまたは適切なものには〇、誤っているものまたは不適切なものには✕を選択してください。"),_c('br'),_vm._v("問31～問60は、文章の（　　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを選択肢１．〜３．の中から選択してください。 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("試験")]),_c('th',{staticStyle:{"width":"150px"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th',[_vm._v("試験")]),_c('th',{staticStyle:{"width":"150px"}})])
}]

export { render, staticRenderFns }