
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import StorageService from "../services/storage";
import Question from "@/models/question";

@Component
export default class Calc extends Vue {
  private isEnd: boolean = false;
  private interval: any = null;
  private goalDate: Date = new Date();
  private timerStr: string = "";
  public questions: Question[] = [];
  private expression: string = ""
  private current: string[] = [""]
  private result: string = '0'
  @Prop()
  private isInput!: boolean

  mounted() {
    this.questions = this.$store.state.data;
    window.addEventListener('keydown', this.numericInput);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.numericInput);
  }

  numericInput(event: any) {
    if (!this.isInput) return
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if ((keycode >= 48 && keycode <= 57)) {
      this.press(String(Number(keycode) - 48));
    }
    if ((keycode >= 96 && keycode <= 105)) {
      this.press(String(Number(keycode) - 96));
    }
    if (keycode == 190 || keycode == 110) {
        this.press('.')
      }
      else if (keycode == 43 || keycode == 107) {
        this.pressExe('+')
      }
      else if (keycode == 45 || keycode == 109) {
        this.pressExe('-')
      }
      else if (keycode == 42 || keycode == 106) {
        this.pressExe('*')
      }
      else if (keycode == 47 || keycode == 111) {
        this.pressExe('/')
      }
      else if (keycode == 61 || keycode == 13) {
        this.calculate()
        event.preventDefault()
      }
      else if (keycode == 8) {
        this.backspace()
      }
      else if (keycode == 46) {
        this.calcClear()
      }

      
  }

  press(button: string) {
    if (button == "." && this.current[this.current.length - 1].includes(".")) return
    if (!this.isCanInput()) return

    

    this.current[this.current.length - 1] = this.current[this.current.length - 1] + button;
    this.current = JSON.parse(JSON.stringify(this.current))
    this.expression = this.getExpression()
    const last = this.current[this.current.length - 1]
    if (!isNaN(Number(last)) && last != "" ) {
      this.result = last
    }
    this.setFontSize(this.result, "result");
    this.setFontSize(this.expression, "formula");
  }
  pressExe(button: string) {
    // 演算子だけとか追加などは不可
    if (this.current.length == 1 && this.current[0] == "") {
      if (this.result == "0") {
        return
      } else {
        const resultNum = this.result.replace(/\,/g, "")
        if (!isNaN(Number(resultNum)) && resultNum != "" ) {
          this.current[0] = resultNum
        }
      }
    }
    if (!this.isCanInput()) return


    if (this.current.length > 1 && this.current[this.current.length - 1] == "") {
      const before = this.current[this.current.length - 2]
      if (
        before == "+" ||
        before == "-" ||
        before == "*" ||
        before == "/"
      ) {
        this.current[this.current.length - 2] = button
        this.expression = this.getExpression()
        this.setFontSize(this.result, "result");
        this.setFontSize(this.expression, "formula");
        return
      } else {
        return
      }
    } else if (this.current.length > 0 && this.current[this.current.length - 1] != "") {
      const last = this.current[this.current.length - 1]
      if (last.charAt(last.length - 1) == ".") return
    }
    this.current.push(button);
    this.current.push("");
    this.expression = this.getExpression()
    this.setFontSize(this.result, "result");
    this.setFontSize(this.expression, "formula");
  }
  calculate() {
    try {
      const sum = this.current.join(" ")
      const resultNum = Number(Number(eval(sum).toString()).toFixed(10));
      this.result = String(resultNum.toLocaleString('ja-JP', { minimumFractionDigits: 0, maximumFractionDigits: 10 }))
      this.current = [""]
      this.expression = this.getExpression()
      this.setFontSize(this.result, "result");
      this.setFontSize(this.expression, "formula");
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.calcClear();
      }
    }
  }
  calcClear() {
    this.current = [""];
    this.result = "0"
    this.expression = this.getExpression()
    this.setFontSize(this.result, "result");
    this.setFontSize(this.expression, "formula");
  }

  backspace() {
    if (this.current.length == 0) return
    const before = this.current[this.current.length - 1]

    if (before == "+" ||
      before == "-" ||
      before == "*" ||
      before == "/"
    ) {
      this.current.pop()
    } else if (this.current.length > 1 && before == "") {
      this.current.pop()
      this.current.pop()
    } else {
      if (before.length == 2 && before.charAt(0) == "-") {
        this.current.pop()
      } else if (before.length <= 1) {
        this.current.pop()
        this.result = "0"
        this.setFontSize(this.result, "result");
      } else {
        this.current[this.current.length - 1] = before.slice(0, -1)
        this.result = this.current[this.current.length - 1]
        this.expression = this.getExpression()
        this.setFontSize(this.expression, "formula");
        this.setFontSize(this.result, "result");
        return
      }
    }

    if (this.current.length == 0) {
      this.current.push("")
    }
    this.expression = this.getExpression()
    this.setFontSize(this.expression, "formula");
  }

  plusMinus() {
    if (this.current.length == 0) return
    if (!this.isCanInput()) return
    let last = this.current[this.current.length - 1]
    if (last == "") return
    if (!isNaN(Number(last)) && last.charAt(last.length - 1) != ".") {
      this.current[this.current.length - 1] = last.charAt(0) === '-' ? last.slice(1) : '-' + last;
    }
    this.current = JSON.parse(JSON.stringify(this.current))
    this.expression = this.getExpression()
    this.setFontSize(this.expression, "formula");
  }

  getExpression() {
    let str = this.current.join("")
    str = str.replace(/\*/g, "×")
    return str
  }

  isCanInput() {
    let str = this.current.join("")
    str = str.replace(/\*/g, "×")
    if (str.length > 34) return false;
    return true
  }

  setFontSize(str: string, id: string) {
    str = str.replace(/ /g, "")
    str = str.replace(/\,/g, "")
    str = str.replace(/\./g, "")
      const dom1 = document.getElementById(id)!
    if (str.length <= 12) {
      dom1.style.fontSize = "25.5px" 

    } else {
      const per = 220 / str.length * 26 / 17
      dom1.style.fontSize = String(per) + "px"
    }
    
  }

  @Emit("close")
  close() {
    return;
  }
}
