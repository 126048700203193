
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import Shiken from "../models/shiken";
import Menu from "@/components/Menu.vue"
import Calc from "@/components/Calc.vue"

@Component({
  components: {
    Menu,
    Calc
  }
})
export default class QuestionPage extends Vue {
  public current: object | null = null;
  private id: string = this.$route.params.id ?? "0";
  private shikenId: string = this.$route.params.shikenId ?? "0";
  private index: number = 0;
  private indexs: number[] = [];
  public questions: Question[] = [];
  private question: Question = new Question();
  private isEnd: boolean = true;
  private isFirst: boolean = false;
  private isLast: boolean = false;
  private isOpenMenu: boolean = false
  private isVisibleCalc: boolean = false


  created() {
    history.pushState(null, "", null);

    const shiken: Shiken | null = new HttpClient().getShiken(
      Number(this.shikenId)
    );
    if (shiken == null) return new BasicUtil().backtoTop(Number(this.shikenId));
    // const isAuth = BasicUtil.isAuth(String(shiken!.grade));
    // if (!isAuth) return (location.href = "/");
    this.questions = this.$store.state.data;
    if (this.questions.length <= 0) return new BasicUtil().backtoTop(Number(this.shikenId));

    this.isEnd = false;
    window.scrollTo(0, 0);

    const vm = this;

    this.index = Number(vm.id) - 1;
    this.$store.commit("setIndex", this.index);
    if (this.index == 0) this.isFirst = true;
    this.question = this.questions[this.index];

    if (this.index >= this.questions.length - 1) this.isLast = true;

    this.setCurrent(this.question.id, this.question.answerType);
  }

  mounted() { }

  private setCurrent(id: number, type: number) {
    let current = null;
    if (type == 1) {
      current = require("./questions/choice.vue");
    }
    this.current = current.default;
  }

  private prev() {
    if (this.index <= 0) {
      return;
    }

    let prev: any = this.questions[this.index - 1];

    this.$router.push(`/exam/${prev.shikenId}/${prev.no}`, () => { });
  }

  private next() {
    if (this.index >= this.questions.length - 1) {
      return;
    }

    let next: any = this.questions[this.index + 1];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }

  private jump(i: number) {
    if (i < 0) return;
    let next: any = this.questions[i];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }

  private bookmark() {
    if (this.questions[this.index].isBookmark == undefined) this.questions[this.index].isBookmark = false
    this.questions[this.index].isBookmark = !this.questions[this.index]
      .isBookmark;

    this.$store.commit("setQuestions", this.questions);
    this.questions = JSON.parse(JSON.stringify(this.$store.state.data));
  }

  public async answer() {
    this.questions = JSON.parse(JSON.stringify(this.$store.state.data));
  }

  public openMenu() {
    this.isOpenMenu = !this.isOpenMenu
  }

  public close() {
    this.isOpenMenu = false
  }

  private end() {
    if (!confirm("終了しますか？")) return;
    if (this.$store.state.isRe) {
      this.$router.push(`/`);
    } else {
      if (this.shikenId == "1") {

        this.$router.push(`/end`);
      } else {
        this.$router.push(`/end2`);

      }
    }
  }

  public openCalc() {
    this.isVisibleCalc = !this.isVisibleCalc
  }

  // public overtime() {
  //   const vm = this
  //   this.isEnd = true;
  //   setTimeout(function() {
  //     alert("制限時間が終了しました");
  //     vm.$router.push(`/result`);
  //   }, 500);
  // }
}
