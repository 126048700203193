
import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";

@Component
export default class Menu extends Vue {
  @Prop()
  private index!: number;
  @Prop()
  public questions!: Question[]

  created() {

  }
  mounted() { }


  @Emit("close")
  close() {
    return
  }

  private jump(i: number) {
    if (i < 0) return;
    if (i == this.index) return
    let next: any = this.questions[i];
    this.$router.push(`/exam/${next.shikenId}/${next.no}`, () => { });
  }
}
