
import { Component, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Shiken from "../models/shiken";
import HttpClient from "../services/httpClient";

@Component({
  components: {}
})
export default class ResultView extends Vue {
  public questions: Question[] = [];
  private grade: number = 3;
  private all: number = 0;
  private score: number = 0;
  private scores: number[] = [0, 0, 0, 0, 0, 0]
  private isPass: boolean = false;
  private shikenId: number = 0
  created() {
    history.pushState(null, "", null);
    this.questions = this.$store.state.data;
    if (this.questions.length == 0) return (location.href = "/");

    const question = this.questions[0];
    const shiken: Shiken | null = new HttpClient().getShiken(
      Number(question.shikenId)
    );
    if (shiken == null) return (location.href = "/");
    this.shikenId = shiken.id

    this.grade = shiken.grade;
    const vm = this;
    this.questions.forEach((q: Question) => {
      vm.all += q.score;
      if (q.result) vm.score += q.score;
    });
    if (this.score >= this.all * 0.6) {
      this.isPass = true;
    }
  }
  mounted() { }

  close() {
    location.href = `/`
  }


  isIncorrect(question: Question | undefined) {
    if (question == undefined) return false;
    return !question.result;
  }


  getDisplayAnswer(num: number, question: Question) {
    if (question.choice1 == "○" && question.choice2 == "✕") {
      if (num == 1) return "○"
      if (num == 2) return "×"
      return "-"
    }
    return num
  }

  private restudy() {
    const datas = this.questions.filter(q => {
      return q.currentChoice != q.answer
    })
    if (datas.length <= 0) return
    const shikenId = datas[0].shikenId
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    this.$store.commit("setGoal", new Date().getTime() + 60 * 60 * 1000);
    this.$store.commit("setIsRe", true)
    this.$router.push(`/exam/${shikenId}/1`);
  }

  private endExam() {
    
    if (!window.confirm("終了しますか？")) return
    if (this.shikenId == 1) return location.href = "/"
    location.href = "/home2"
  }

  getChoiceString(q: Question) {
    if (q.currentChoice == undefined) return ""
    if (q.choice1 == "〇") {
      return q.currentChoice == 1 ? "〇" : "×"
    }
    return String(q.currentChoice)
  }

  getAnswerString(q: Question) {
    if (q.answer == undefined) return ""
    if (q.choice1 == "〇") {
      return q.answer == 1 ? "〇" : "×"
    }
    return String(q.answer)
  }
}
